import React, { Suspense, useEffect, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Loading } from "./aiapps/loading";

const queryClient = new QueryClient();

// Lazy-loaded components
const Home = lazy(() => import("./home"));
const Pricing = lazy(() => import("./pricing"));
const Upgrade = lazy(() => import("./upgrade"));
const UpgradePay = lazy(() => import("./pay-upgrade"));
const UpgradePayEnt = lazy(() => import("./pay-upgrade/index_ent"));
const Downgrade = lazy(() => import("./downgrade"));
const Register = lazy(() => import("./register"));
const Login = lazy(() => import("./login"));
const Forgot = lazy(() => import("./forgot"));
const PasswordSent = lazy(() => import("./password-sent"));
const Reset = lazy(() => import("./resetpass"));
const Pay = lazy(() => import("./pay"));
const PayV1 = lazy(() => import("./pay/index_01"));
const PayV2 = lazy(() => import("./pay/index_02"));
const PayV3 = lazy(() => import("./pay/index_03"));
const PayV4 = lazy(() => import("./pay/index_04"));
const PayV5 = lazy(() => import("./pay/index_05"));
const PayV6 = lazy(() => import("./pay/index_05"));
const PayV7 = lazy(() => import("./pay/index_06"));
const PayRef = lazy(() => import("./pay-reference/index"));
const PayConfirm = lazy(() => import("./pay-confirm/index"));
const Thankyou = lazy(() => import("./thankyou"));
const ThankYouFake = lazy(() => import("./thankyou/index_fake"));
// const ThankYouFakeBot = lazy(() => import("./thankyou/index_fake_bot"));
const MyAccount = lazy(() => import("./my-account"));
const Manage = lazy(() => import("./manage-account"));
const ChangeCard = lazy(() => import("./change-card"));
const EmailTemplates = lazy(() => import("./emailtemplates"));
const ActiveSession = lazy(() => import("./active-session"));
const NotFoundPage = lazy(() => import("./404"));
const RedirectAccount = lazy(() => import("./redirect-account-required"));
const SplashPage = lazy(() => import("./splashscreen"));
// LPs
const StartStableDiffusion = lazy(() => import("./lp/start-stable-diffusion"));
const StartChatGPT = lazy(() => import("./lp/start-chat-gpt"));
const StartChatGPTgo = lazy(() => import("./lp/start-chatgpt-go"));
const StartChatGPTv2 = lazy(() => import("./lp/start-chatgpt-v2"));
const Text2Image = lazy(() => import("./lp/text-to-image"));
const Survey = lazy(() => import("./survey"));
const Disclaimer = lazy(() => import("./footer/disclaimer"));
const Resume = lazy(() => import("./resume-subscription"));
const Review = lazy(() => import("./review"));


function App() {
  useEffect(() => {
    import("./tailwind.scss");
    import("./index.css");
  }, []);

  return (
    <Router>
      <div>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Loading />}>
            <div className="md:min-h-[85vh]">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/upgrade" element={<Upgrade />} />
                <Route path="/upgrade/:id" element={<UpgradePay />} />
                <Route path="/upgrade-ent/:member" element={<UpgradePayEnt />} />
                <Route path="/downgrade" element={<Downgrade />} />
                <Route path="/downgrade/:id" element={<UpgradePay />} />
                <Route path="/register" element={<Register />} />
                <Route path="/pay" element={<Pay />} />
                <Route path="/pay/1LCXJMZNX6" element={<Pay />} />
                <Route path="/pay/bH0w05VJXk" element={<PayV1 />} />
                <Route path="/pay/VS6lni4hKx" element={<PayV2 />} />
                <Route path="/pay/uHTinVqsUl" element={<PayV3 />} />
                <Route path="/pay/mcWiDilmgQ" element={<PayV4 />} />
                <Route path="/pay/2FQH9T5Y7P" element={<PayV5/>} />
                <Route path="/pay/LZQ6N8V2A" element={<PayV6/>} />
                <Route path="/pay/FSQ6N8V2A" element={<PayV7/>} />
                <Route path="/payment-reference" element={<PayRef />} />
                <Route path="/payment-confirm" element={<PayConfirm />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/password-sent" element={<PasswordSent />} />
                <Route path="/resetpass" element={<Reset />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/ty" element={<ThankYouFake />} />
                {/* <Route path="/typage" element={<ThankYouFakeBot />} /> */}
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/manage-account" element={<Manage />} />
                <Route path="/change-card" element={<ChangeCard />} />
                <Route path="/emailtemplates" element={<EmailTemplates />} />
                <Route path="/resume" element={<Resume />} />
                <Route
                  path="/redirect-account-required"
                  element={<RedirectAccount />}
                />
                <Route
                  path="/start-stable-diffusion"
                  element={<StartStableDiffusion />}
                />
                <Route path="/start-chat-gpt" element={<StartChatGPT />} />
                <Route path="/start-chatgpt-go" element={<StartChatGPTgo />} />
                <Route path="/start-chatgpt-v2" element={<StartChatGPTv2 />} />
                <Route path="/text-to-image" element={<Text2Image />} />
                <Route path="/splash" element={<SplashPage />} />
                <Route path="/survey" element={<Survey />} />
                <Route path="/active-session" element={<ActiveSession />} />
                <Route path="/*" element={<NotFoundPage />} />
                <Route path="/review" element={<Review />} />
              </Routes>
            </div>
            <Disclaimer/>
          </Suspense>
        </QueryClientProvider>
        <div className="loader-container">
          <div className="overlay"></div>
          <div className="lds-ai">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </Router>
  );
}
export default App;
